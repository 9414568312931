$white: #e7e7e7;
$red: #c51010;
$teal: #0e3842;

// These rules fill the viewport
html {
  min-height: 100%;
}
body,
#root {
  height: 100vh;
}
.container {
  min-height: 100%;
  width: 100vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  background: $red;
  background: -webkit-linear-gradient(to right, $red, #3f6673);
  background: linear-gradient(to right, $red, #3f6673);
  color: $white;
  // This spaces out the sections evenly across the screen
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  font-size: 32px;
  font-weight: bold;
}
h1 sup {
  font-size: 16px;
}
h2 {
  font-size: 24px;
  font-weight: bold;
}
p {
  font-size: 16px;
  font-weight: lighter;
}
h4 {
  font-size: 16px;
  font-weight: bold;
}

button {
  border: none;
  padding: 12px;
  font-size: 12px;
  color: $red;
  border-radius: 4px;
  margin-right: 16px;
  font-weight: bold;
  margin-top: 16px;
}
a {
  margin: 0 8px;
  color: #c51010;
  text-decoration: none;
  font-weight: bold;
}
a:hover {
  font-style: italic;
  margin: 0 7.5px;
}
header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 32px;
  background: rgba(0, 0, 0, 0.5);
  img {
    width: 40px;
    margin-right: 16px;
    display: block;
  }
}

main {
  display: flex;
  justify-content: space-between;
  background-image: url(/images/recPattern.svg);
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: bottom right;

  @media only screen and (min-width: 1001px) {
    flex-direction: row;
    padding: 32px 5vw;
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    padding: 32px 10vw;
  }

  .downloads {
    margin: 0 64px;
  }
  .slick-dots li button::before {
    color: white;
  }
  .slick-dots li.slick-active button:before {
    color: white;
  }
  figure {
    margin: 0;
    img {
      @media only screen and (min-width: 1001px) {
        width: 55vw;
      }
      @media only screen and (max-width: 1000px) {
        width: 80vw;
      }
    }
  }
}
.phone-message {
  display: none;
}
.feature-section {
  color: white;
  background: $teal;
  width: 100vw;
  margin: 0;
  display: flex;

  @media only screen and (min-width: 1001px) {
    width: 90vw;
    flex-direction: row-reverse;
    padding: 32px 5vw;
    justify-content: space-between;
    div {
      width: 60vw;
      margin: 0;
    }
    .left-ideas {
      width: 25vw;
    }
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 32px 10vw;
    width: unset;
    div {
      width: 75vw;
      margin: 0;
    }
  }
}
footer {
  display: flex;
  text-align: center;
  width: calc(100vw - 64px);
  align-items: center;
  background: $white;
  color: $red;
  padding: 32px;
  img {
    width: 43px;
    height: 30px;
    margin-right: 8px;
  }
  p {
    margin: 0;
  }
}
.right-side {
  text-align: center;
  .image-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .screenshots {
    padding-bottom: 24px;
    @media only screen and (min-width: 1001px) {
      width: 55vw;
    }
    @media only screen and (max-width: 1000px) {
      width: calc(100vw - 64px);
      padding-bottom: 24px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .downloads {
    display: none;
  }
}
@media only screen and (max-device-width: 767px) {
  h1 {
    font-size: 24px;
    sub {
      font-size: 12px;
    }
  }
  h2 {
    font-size: 20px;
  }
  p,
  footer {
    font-size: 12px;
  }
  main {
    flex-direction: column-reverse;
    padding: 32px;
    figure {
      display: flex;
      justify-content: center;
      img {
        width: 250px;
        height: 200px;
      }
    }
  }
  header {
    padding-left: 16px;
    justify-content: center;
    img {
      margin-right: 4px;
      width: 32px;
    }
  }
  .downloads {
    display: none;
  }
  .phone-message {
    display: flex;
  }
  footer {
    padding: 16px 32px;
    width: calc(100vw - 64px);
    flex-direction: column;
    img {
      padding-bottom: 8px;
    }
  }
  .screenshots {
    width: 100%;
  }
}
@media only screen and (min-width: 1600px) {
}
